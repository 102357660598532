import { useState, useRef, useEffect } from "react";
import { LabAvatar, AvatarEventHeader, AvatarEventType } from "byted-ailab-speech-sdk";
import './index.scss'
import { Modal, Spin } from 'antd';
import { appObj } from '../../utils/index'


const wsUrl = 'ws://192.168.110.249:33333/virtual-human/test'

function App() {
  const videoRef = useRef(null);
  const setIntervalWesocketPush = useRef(null)
  const Socket = useRef(null);
  const [loading, setLoading] = useState(true);
  const [sex, setSex] = useState(2);

  // 用于操作聊天列表元素的引用
  const [avatar] = useState(
    LabAvatar({
      debug: true,
      onEvent(e) {
        if (e.header === AvatarEventHeader.EventMessage) {
          if (e.type === AvatarEventType.VoiceStart) {
            console.info("[ onVoiceStart ] > 播报开始");
          }
          if (e.type === AvatarEventType.VoiceEnd) {
            console.info("[ onVoiceEnd ] > 播报停止");
          }
        }
      },
      onFinishInit() {
        setTimeout(() => {
          setLoading(false)
        }, 600)
        console.info("[ onFinishInit ] >");
      },
      onAvatarMessage: (message) => {
        console.info(message);
      },
      onErrorOccur: (error) => {
        const { message } = JSON.parse(error);
        console.info("交互服务报错", message);
      },
      onAutoplayFailed: () => new Promise((resolve) => {
        Modal.confirm({
          title: '需要浏览器允许播放音频，请确认',
          onOk: () => {
            // setTimeout(()=>{
            //   sendDefaultMsg();
            // }, 500)
            resolve(true);
          }
        })
      })
    })
  );

  const connect = () => {
    avatar.connect({
      videoElement: videoRef.current,
      url: "wss://openspeech.bytedance.com/virtual_human/avatar_biz/echo", // 连接url，具体含义请参照参数说明
      config: {
        tta: {
          role: sex === 2 ? "GuoXiaoyuan" : 'GuoXiaoyuan',//请参照配置参数说明 GuoXiaoyuan // XiaoHuan LiuXuan
          extra_param: {
            // background: imgList[active].bg,
            role_conf: {
              role_left_offset: sex === 2 ? 120 : 50,
              role_width: 900,
              role_top_offset: 450
            }
          }
        },
        // 新的接入认证：
        // appid kd8joprtcelxjzmx
        // token qsooRFf6w1aRAgTBRoJJ6YdT0UTxCedL
        // old
        // appid  suabwhhiw7z2ga6e
        // token： TQEZfhvWpQcIlOJ9Kzw02X55dkYCmxlW
        request: {
          app_id: appObj.app_id,  //请参照配置参数说明
          req_id: guid(), // 此处使用 node-uuid
          uid: '2100326369',  // 请参照配置参数说明
          token: appObj.token,
        },
      },
    });
  };

  const newWebSocket = () => {
    Socket.current = new WebSocket(wsUrl)
    Socket.current.onopen = onopenWS
    Socket.current.onmessage = onmessageWS
    Socket.current.onerror = onerrorWS
    Socket.current.onclose = oncloseWS
  }


  /** 打开WS之后发送心跳 */
  const onopenWS = () => {
    console.log('websocket链接成功')
    sendPing()
  }

  const sendPing = (time = 10000, ping = 'ping') => {
    clearInterval(setIntervalWesocketPush.current)
    Socket.current.send(JSON.stringify({ 'ping': 'heart' }))
    setIntervalWesocketPush.current = setInterval(() => {
      Socket.current.send(JSON.stringify({ 'ping': 'heart' }))
    }, time)
  }

  /** WS数据接收统一处理 */
  const onmessageWS = e => {
      if (e && e.data && e.data!=='health') {
        const json = JSON.parse(e.data)
        avatar && avatar.sendText(json?.speechText)
      }
      
  }


  /** 断开重连 */
  const oncloseWS = () => {
    console.log('websocket已断开')
    Socket.current = null
  }

  /** 连接失败重连 */
  const onerrorWS = () => {
    Socket.current && Socket.current.close()
    clearInterval(setIntervalWesocketPush.current)
    console.log('连接失败重连中')
    if (Socket.current.readyState !== 3) {
      Socket.current = null
      newWebSocket()
    }
  }


  useEffect(() => {
    connect();
    newWebSocket();
    return () => {
      avatar.disconnect();
    };
  }, [sex]);

  const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }




  return (
    <div className="wrapper-perform">
      <div className="content">
        <div className="videos" >
          <Spin size="large" className="loading" spinning={loading}>
            <div className="video-ref" ref={videoRef}>
            </div>
          </Spin>
        </div>
      </div>

    </div>
  );
};

export default App;

