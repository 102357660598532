
import axios from 'axios';
import { Toast as message } from 'antd-mobile'
import { getToken } from './auth'
// 创建axios实例
const service = axios.create({
  // baseURL 在request拦截器中指定
  timeout: 800000, // 请求超时时间
  withCredentials: true,
  // 格式化 query 中数组格式
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if(!config.baseURL){
      config.baseURL = 'https://chatbot.infodator.com/lingjing_api/' //'https://cgxwsoz.infodator.com/prod-api/api/';
    }
    if (getToken()) {
      config.headers.Authorization = 'Bearer ' + getToken(); 
    }
    // config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json';
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // // 如果请求的返回类型是流，则直接返回 data
    // if (response.config.responseType === 'blob') {
    //   return res;
    // }
    // if (res.code === 403) {
    //   removeToken();
    //   window.location.reload();
    //   return false;
    // }
    // // if the custom code is not 200, it is judged as an error.
    // if (res.code !== 200 && res.code !== 0) {
    //   if (isWhiteList(response.config.url)) {
    //     return Promise.reject(res.msg || '请求异常');
    //   }
    //   return message.info(res.msg || '请求异常', res.code);
    // }
    return res;
  },
  (error) => {
    if (error.response) {
      const { data } = error.response;
      // 目前后端返回会出现有 code 无 msg
      if (data && data.code) {
        return message.info(data.msg || '请求异常', data.code);
      }
      return message.info(`请求异常：${error.response.statusText}`);
    }

    if (error.request) {
      return message.info('请求异常：无返回结果');
    }

    return message.warning(error.message);
  }
);

export default service;

