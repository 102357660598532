
import { useState, useRef, useEffect } from "react";
import { LabAvatar, AvatarEventHeader, AvatarEventType } from "byted-ailab-speech-sdk";
import classNames from 'classnames'
import './index.scss'
import { uploadVoice, quertStr } from '../../api/index'
import { Modal, Input, SpinLoading, Tabs, Toast } from 'antd-mobile';
import RecordRTC from 'recordrtc';
import captureMicrophone from '../../utils/audioRTC'
import monitorSoftKeyboard from '../../utils/audioRTC'
import { createSocket, sendWSPush } from '../../utils/websocket'
import { appObj } from '../../utils/index'
import img from '../../static/ibot.svg'
import jp from '../../static/jp.png'
import yy from '../../static/yy.png'
import kf from '../../static/kf.png'
import zw from '../../static/zw.png'
import jr from '../../static/jr.png'
import sw from '../../static/sw.png'
import openSound from '../../static/openSound.png'
import closeSound from '../../static/closeSound.png'
import boy from '../../static/boy.svg'
import girl from '../../static/girl.svg'
import boy1 from '../../static/boy1.svg'
import girl1 from '../../static/girl1.svg'
import send from '../../static/send.svg'
let recorder = null;

function App() {
  const videoRef = useRef(null);
  const [val, setValue] = useState('')
  const [messageList, setMessageList] = useState(sessionStorage.getItem('socket') ? JSON.parse(sessionStorage.getItem('socket')) : []);
  const setIntervalWesocketPush = useRef(null)

  const imgList = [
    {
      bg: 'https://zpt.vatonline.cn/minio/upload/20230329/1680071406069.png',
      logo: kf,
    },
    {
      bg: 'https://zpt.vatonline.cn/minio/upload/20230329/1680071404451.png',
      logo: jr,
    },
    {
      bg: 'https://zpt.vatonline.cn/minio/upload/20230329/1680071405635.png',
      logo: zw,
    },
    {
      bg: 'https://zpt.vatonline.cn/minio/upload/20230331/1680245038561.png',
      logo: sw
    }
  ]
  const [active, setActive] = useState(0);
  const showIconRef = useRef(true);
  const inputValueRef = useRef('');
  const Socket = useRef(null);
  const [showIcon, setShowIcon] = useState(true);
  const [showWave, setShowWave] = useState(false);
  const handle = useRef(null)
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(sessionStorage.getItem('openStatus') ? sessionStorage.getItem('openStatus') === 'true' : true);
  const [sex, setSex] = useState(2);
  const [viewport, setViewport] = useState(false);
  const microphone = useRef('');
  const [hideBottom, setHideBottom] = useState(false);
  const [detailItem, setDetailItem] = useState({
    voiceItems: [{

    }]
  });

  // 用于操作聊天列表元素的引用
  const chatListRef = useRef(null)
  const [avatar] = useState(
    LabAvatar({
      debug: true,
      onEvent(e) {
        if (e.header === AvatarEventHeader.EventMessage) {
          if (e.type === AvatarEventType.VoiceStart) {
            console.info("[ onVoiceStart ] > 播报开始");
          }
          if (e.type === AvatarEventType.VoiceEnd) {
            console.info("[ onVoiceEnd ] > 播报停止");
          }
        }
      },
      onFinishInit() {
        // setTimeout(()=>{
        setLoading(false)
        // }, 600)
        console.info("[ onFinishInit ] >");
      },
      onAvatarMessage: (message) => {
        console.info(message);
      },
      onErrorOccur: (error) => {
        const { message } = JSON.parse(error);
        console.info("交互服务报错", message);
      },
      onAutoplayFailed: () => new Promise((resolve) => {
        console.log('*****')
        Modal.confirm({
          title: '需要浏览器允许播放音频，请确认',
          onAction: () => {
            // setTimeout(()=>{
            //   sendDefaultMsg();
            // }, 500)
            resolve(true);
          }
        })
      })
    })
  );

  const changeValue = (num) => {
    if (active === num) {
      return;
    } else {
      setLoading(true)
      const div = document.getElementById('playdiv-15');
      div && div.parentNode.removeChild(div);
      setActive(num);
    }

  }

  const connect = () => {
    avatar.connect({
      videoElement: videoRef.current,
      url: "wss://openspeech.bytedance.com/virtual_human/avatar_biz/echo", // 连接url，具体含义请参照参数说明
      config: {
        tta: {
          role: sex === 2 ? "LiuXuan" : 'XiaoHuan',//请参照配置参数说明 GuoXiaoyuan // LiuXuan  // 
          // clothes_type: 'clothes1',
          // pose_tpye: 'standing_fullbody',
          // voice_type: 'BV405',
          // video: { 
          // "video_width":1080,
          // "video_height":1920,
          // "I_frame_interval":25
          //  },
          extra_param: {
            background: imgList[active].bg,
            role_conf: {
              role_left_offset: 0,
              role_width: 1080,
              role_top_offset: 1200
            }
          }
        },
        // tts: {
        //   role_conf: {
        //     "role_left_offset": 1000, 
        //     // pose_type: "standing_fullbody"
        //   },
        // },
        request: {
          app_id: appObj.app_id,  //请参照配置参数说明
          req_id: guid(), // 此处使用 node-uuid
          uid: '2100326369',  // 请参照配置参数说明
          token: appObj.token,
        },
      },
    });
  };

  const newWebSocket = () => {
    const socketUrl = window.location.host === 'localhost' ? 'ws://124.71.186.213:13385/stream_chat' : 'wss://cgxwsoz.infodator.com/ws/';
    Socket.current = new WebSocket(socketUrl)
    Socket.current.onopen = onopenWS
    Socket.current.onmessage = onmessageWS
    Socket.current.onerror = onerrorWS
    Socket.current.onclose = oncloseWS
  }


  /** 打开WS之后发送心跳 */
  const onopenWS = () => {
    console.log('websocket链接成功')
    sendPing()
  }

  const sendPing = (time = 10000, ping = 'ping') => {
    clearInterval(setIntervalWesocketPush.current)
    Socket.current.send(JSON.stringify({ 'ping': 'heart' }))
    setIntervalWesocketPush.current = setInterval(() => {
      Socket.current.send(JSON.stringify({ 'ping': 'heart' }))
    }, time)
  }

  /** WS数据接收统一处理 */
  const onmessageWS = e => {
    if (e && e.data && e.data!=='health') {
      let msgList = JSON.parse(sessionStorage.getItem('socket'))
      let webSocketStr = sessionStorage.getItem('webSocketStr') || ''
      let webSocketStart = sessionStorage.getItem('webSocketStart')
      if(webSocketStart ===null){
        sessionStorage.setItem('webSocketStart', true)
      }
      if(e.data.startsWith('{"')){
        let obj = JSON.parse(e.data)
        if(obj.flag == 'start'){
        }else if(obj.flag == 'end' ){
          let str = msgList[msgList.length - 1].message;
            avatar && avatar.sendText(str);
          sessionStorage.setItem('webSocketStart', false)
          sessionStorage.setItem('webSocketStr', '')
        }
      }else{
        if(webSocketStart===null ||  webSocketStart){
          webSocketStr = webSocketStr + (e.data ? e.data.replace('<br><br>', '<br/>') : e.data)
        }
        sessionStorage.setItem('webSocketStr', webSocketStr)
        msgList[msgList.length - 1] = {
          loading: false,
          type: 1,
          message: webSocketStr
        }
        if(webSocketStr.length < 10){
          setMessageList(msgList)
          sessionStorage.setItem('socket', JSON.stringify(msgList))
        }else{
          setTimeout(()=>{
            setMessageList(msgList)
            sessionStorage.setItem('socket', JSON.stringify(msgList))
          }, 300)
        }
      }
    }
    // if (e && e.data ) {
    //   if (e.data !== 'health' && e.data.flag !== 'end') {
    //     new Promise((resolve) => { resolve() }).then(res => {
    //       let msgList = JSON.parse(sessionStorage.getItem('socket'))
    //       msgList[msgList.length - 1] = {
    //         loading: false,
    //         type: 1,
    //         message: e.data
    //       }
    //       setTimeout(() => {
    //         setMessageList(msgList);
    //       }, 1000)
    //       sessionStorage.setItem('socket', JSON.stringify(msgList))
    //     })
    //   } else if (e.data.flag == 'end') {
    //     let openStatus = sessionStorage.getItem('openStatus');
    //     let obj = JSON.parse(sessionStorage.getItem('socket'))
    //     let str = obj[obj.length - 1].message;
    //     if ((openStatus === 'true' || !openStatus)) {
    //       avatar.sendText(str);
    //     }
    //   }
    // }
  }


  /** 断开重连 */
  const oncloseWS = () => {
    console.log('websocket已断开')
    Socket.current = null
  }

  /** 连接失败重连 */
  const onerrorWS = () => {
    Socket.current && Socket.current.close()
    clearInterval(setIntervalWesocketPush.current)
    console.log('连接失败重连中')
    if (Socket.current.readyState !== 3) {
      Socket.current = null
      newWebSocket()
    }
  }

  // 注册监听事件

  // useEffect(() => {
  //   window.addEventListener('onmessageWS', getsocketData)
  //   return () => {
  //     window.removeEventListener('onmessageWS', getsocketData)
  //   }
  // })

  const getsocketData = e => {
    // 创建接收消息函数
    const data = e && e.detail.data
    if (data) {
      const json = JSON.parse(data)
      let msgList = JSON.parse(sessionStorage.getItem('socket'))
      if (json.message) {
        const message = JSON.parse(json.message);
        let newContent = '<div style="display: flex;">';
        if (message.length > 0) {
          if (message[0].quick_replies && message[0].quick_replies.length > 0) {
            newContent = message[0].quick_replies.map((val, i) => {
              return `<div data-tagName="payload" class='btn' data-payload=${val.payload} style='' data-name='${val.title}'>${val.title}</div>`
            }).join(' ')
            msgList[msgList.length - 1] = {
              loading: false,
              type: 1,
              message: `
                       <div class='message'>${message[0].text}</div><div style="display: flex;position: absolute;bottom: ${msgList.length > 3 ? 0 : '-50px'}; left: 0;">${newContent}</div>${msgList.length > 3 ? '<div style="height: 50px"></div>' : ''}
                       `
            }
          } else {
            if (message[0].text === 'other') {
              let str = msgList[msgList.length - 2].message;
              sendMsg(str, true)
            } else {
              // msgList[msgList.length - 1] = {
              //   loading: false,
              //   type: 1,
              //   message: `<div class='message'>${message[0].text}</div>`
              // }
              let isFlag = msgList[msgList.length - 1].type === 1 && msgList[msgList.length - 1].message;
              if(isFlag){
                msgList = [
                  ...msgList,
                  {
                    loading: false,
                    type: 1,
                    message: `<div class='message'>${message[0].text}</div>`
                  }
                ]
              }else{
                msgList[msgList.length - 1] = {
                  loading: false,
                    type: 1,
                    message: `<div class='message'>${message[0].text}</div>`
                }
              }
              let openStatus = sessionStorage.getItem('openStatus');
              if ((openStatus === 'true' || !openStatus)) {
                avatar && avatar.sendText(message[0].text);
              }
              setMessageList(msgList)
              sessionStorage.setItem('socket', JSON.stringify(msgList))
            }
          }
        }
      } else {
        if (json.cmd === 'setid') {
        }
      }

    }
  }

  useEffect(() => {
    connect();
    newWebSocket();
    // createSocket();
    return () => {
      avatar.disconnect();
    };
  }, [active, sex]);

  useEffect(() => {
    monitorSoftKeyboard(isUp => {
      if (isUp) {
        setHideBottom(true);
      } else {
        setHideBottom(false);
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, viewport.height)
  }, [viewport])

  useEffect(() => {
    if (!microphone.current) {
      captureMicrophone(function (mic) {
        console.log("获取语音权限", mic)
        microphone.current = mic;
      });
      return;
    }
  }, [])

  const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  // 开始录音
  const startRecording = (index) => {
    var options = {
      type: 'audio',
      numberOfAudioChannels: 1,
      checkForInactiveTracks: false,
      bufferSize: 4096,
      recorderType: RecordRTC.StereoAudioRecorder
    };

    if (recorder) {
      recorder.destroy();
      recorder = null;
    }
    // recordre 实例
    recorder = RecordRTC(microphone.current, options);
    console.log('recorder', recorder)
    recorder.startRecording(); //开始录音
    setShowWave(true)
  }
  // 停止录音
  const stopRecording = (index) => {
    recorder.stopRecording(async () => {
      console.log("停止录音回调")
      var internalRecorder = recorder.getInternalRecorder();
      console.log("停止录音回调internalRecorder", internalRecorder)
      // 左声道
      var leftchannel = internalRecorder.leftchannel;
      // 右声道
      // var rightchannel = internalRecorder.rightchannel;
      console.log("左声道", leftchannel)
      console.log(("啦啦啦", internalRecorder.blob, window.URL || window.webkitURL).createObjectURL(internalRecorder.blob))
      console.log("internalRecorderBlob", internalRecorder.blob)
      // 将录音文件 以文件对象形式传给后端
      var form = new FormData()
      form.append("file", internalRecorder.blob, "录制音频.wav");
      const res = await uploadVoice(form);
      if (res) {
        let response = await quertStr({data: res.data});
        if (response.code === 200) {
          sendMsg(response.content)
        } else {
          Toast.show({
            icon: 'fail',
            content: response.msg,
          })
        }
        recorder.destroy();
        recorder = null;
      }
    });

    setShowWave(false)
  }


  useEffect(() => {
    const current = chatListRef.current;
    current.scrollTop = current.scrollHeight - 10
  }, [messageList])

  const submit = async (str = val) => {
    if (!str) {
      return false;
    } else {
      sendMsg(str)
    }
  }

  const sendMsg = (str, isFirst) => {
    let arr = [], history = [];
    let msgList = sessionStorage.getItem('socket') ? JSON.parse(sessionStorage.getItem('socket')) : []
    msgList.map((val, i) => {
      if (i % 2 === 0) {
        arr[0] = val.message
      } else {
        arr[1] = val.message
        history.push(arr);
        arr = [];
      }
    })
    let newList = [...msgList, {
      type: 2,
      message: str,
      loading: false
    }, {
      type: 1,
      message: '',
      loading: true
    }]
    setMessageList(newList)
    sessionStorage.setItem('socket', JSON.stringify(newList))
    // if (!isFirst) {
    //   let newList = [...msgList, {
    //     type: 2,
    //     message: str,
    //     loading: false
    //   }, {
    //     type: 1,
    //     message: '',
    //     loading: true
    //   }]
    //   setMessageList(newList)
    //   sessionStorage.setItem('socket', JSON.stringify(newList))
    //   let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '12';
    //   const msg = { message: str, userId }
    //   setTimeout(() => {
    //     sendWSPush(msg)
    //   }, 600)
    // } else {
    //   Socket.current && Socket.current.send(JSON.stringify({
    //     query: str,
    //     history: history.length > 5 ? history.slice(history.length - 5, history.length) : history
    //   }))
    // }
    Socket.current && Socket.current.send(JSON.stringify({
      query: str,
      history: history.length > 5 ? history.slice(history.length - 5, history.length) : history
    }))
    // new Promise((resolve) => {
    setValue('');


    // new Promise((resolve) => {
    //   let list = [...msgList, {
    //     type: 2,
    //     message: str,
    //   }]
    //   list.current = list;
    //   setMessageList((num) => {
    //     resolve(list); // 也可以传空值过去
    //     return list;
    //   });
    // }).then(async (res) => {
    //   let newList = [...res, {
    //     type: 1,
    //     message: '',
    //     loading: true
    //   }]
    //   setTimeout(() => {
    //     setMessageList(newList)
    //   }, 1000)
    //   sessionStorage.setItem('socket', JSON.stringify(newList))
    //   setMessageList(newList); // 也可以传空值过去
    // });
  }

  // useEffect(() => {
  //   window.addEventListener('touchstart', onKeyDown); // 添加全局事件
  //   window.addEventListener('touchend', onKeyUp); // 添加全局事件
  //   return () => {
  //     window.removeEventListener('touchstart', onKeyDown); // 销毁
  //     window.removeEventListener('touchend', onKeyUp); // 添加全局事件
  //   };
  // }, []);


  // 键盘事件
  const onKeyDown = (e) => {
    if (!showIconRef.current && !handle.current && e.target.className === 'empty' && microphone.current) {
      handle.current = true;
      startRecording(0)
    }
  };

  const onKeyUp = (e) => {
    if (!showIconRef.current && e.target.className === 'empty disabled' && microphone.current) {
      stopRecording(0)
      handle.current = false;
    }
  };

  const _onChange = e => {
    setValue(e);
    inputValueRef.current = e;
  };

  const changeIcon = (value) => {
    setShowIcon(value)
    showIconRef.current = value
  }


  const soundWave = (
    <div className="loader">
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
    </div>
  )

  const onChangeSex = e => {
    setLoading(true)
    setSex(e)
  };

  const shadow = <div className="shadow">
    松开 发送
  </div>
  return (
    <div className="wrappers web-wrapper">
      <div className="content">
        <div className="videos" >
          <Tabs onChange={(e) => changeValue(Number(e))}>
            <Tabs.Tab title='客服' key='0'>
            </Tabs.Tab>
            <Tabs.Tab title='金融' key='1'>
            </Tabs.Tab>
            <Tabs.Tab title='政务' key='2'>
            </Tabs.Tab>
            <Tabs.Tab title='税务' key='3'>
            </Tabs.Tab>
          </Tabs>
          <div className="title-head">
            <div>
              <img alt="" onClick={() => {
                setOpen(!open)
                sessionStorage.setItem('openStatus', !open)
              }} src={!open ? closeSound : openSound} style={{ width: open ? '22px' : '26px' }} />
            </div>
            <div>
              <img src={sex === 1 ? boy : boy1} onClick={() => onChangeSex(1)} alt='' />
              <img src={sex === 2 ? girl : girl1} onClick={() => onChangeSex(2)} alt='' />
            </div>
          </div>
          {
            loading && (
              <SpinLoading style={{ margin: '0 auto', height: '40vh' }}>

              </SpinLoading>
            )
          }
          <div className="video-ref" ref={videoRef}>
          </div>
        </div>
        <div className="chat-box">
          <ul className="list-content" ref={chatListRef}>
            {
              messageList.map((val, index) => (
                <li key={index} className={classNames('list-item', { 'person1': val.type === 1, 'person2': val.type === 2 })}>
                  <div>
                    {
                      val.type === 1 && (
                        <img src={img} alt={''} className="chunk-chat-row-logo-l" />
                      )
                    }
                  </div>
                  {
                    val.type === 1 && val.loading && !val.message && (
                      <div className="loading1">
                        <div className="rect1"></div>
                        <div className="rect2"></div>
                        <div className="rect3"></div>
                      </div>
                    )
                  }
                  {/* dangerouslySetInnerHTML={{ __html: val.message }} */}
                  {
                    !(val.type === 1 && val.loading && val.message === '') && (
                      <div className='chunk-chat-item' dangerouslySetInnerHTML={{ __html: val.message }}
                      >
                        {/* {val.message} */}
                      </div>
                    )
                  }
                </li>
              ))
            }
          </ul>
          <div className="list-footer">
            <div>
              {
                showIcon ?
                  (
                    <img
                      src={yy}
                      onClick={() => changeIcon(false)}
                      alt={yy}
                      className='icon' />
                  ) :
                  (
                    <img
                      src={jp}
                      onClick={() => changeIcon(true)}
                      alt={jp}
                      className='icon' />
                  )
              }
              {
                showIcon ?
                  (
                    <Input type='text' size='large' onPressEnter={() => submit()} onChange={_onChange} value={val} />
                  ) :
                  (
                    <div
                      className={classNames('empty', { 'disabled': showWave || !microphone.current })}
                      onTouchStart={onKeyDown}
                      onTouchEnd={onKeyUp}
                    >{
                        !microphone.current ? '语音录制禁用' : showWave ? '松开 发送' : '按住 说话'
                      }</div>
                  )
              }
              <div className="submit-btn" onClick={() => submit()}>
                <img src={send} alt={send} />
              </div>
            </div>
          </div>
          {
            showWave ? soundWave : null
          }
          {
            showWave ? shadow : null
          }
        </div>
      </div>

    </div>
  );
};
export default App;

