
import { useState, useRef, useEffect } from "react";
import { LabAvatar, AvatarEventHeader, AvatarEventType } from "byted-ailab-speech-sdk";
import classNames from 'classnames'

import './index.scss'
import Recorder from 'js-audio-recorder';
import { uploadVoice, companyInfo, quertStr } from '../../api/index'
import { Modal, Input, Button, Spin, message } from 'antd';
import { createSocket, sendWSPush } from '../../utils/websocket'
import { appObj } from '../../utils/index'
import img from '../../static/ibot.svg'
import jp from '../../static/jp.png'
import yy from '../../static/yy.png'
import kf from '../../static/kf.png'
import zw from '../../static/zw.png'
import jr from '../../static/jr.png'
import sw from '../../static/sw.png'
import openSound from '../../static/openSound.png'
import closeSound from '../../static/closeSound.png'
import boy from '../../static/boy.svg'
import girl from '../../static/girl.svg'
import boy1 from '../../static/boy1.svg'
import girl1 from '../../static/girl1.svg'
import send from '../../static/send.png'
let recorder = null;

function App() {
  const videoRef = useRef(null);
  const [val, setValue] = useState('')
  const [messageList, setMessageList] = useState(sessionStorage.getItem('socket') ? JSON.parse(sessionStorage.getItem('socket')) : []);
  const setIntervalWesocketPush = useRef(null)

  const imgList = [
    {
      bg: 'http://zpt.vatonline.cn/minio/upload/20240322/ec474e16a68c402dbfbe7bd7e062ca26.png',
      logo: kf,
    },
    {
      bg: 'http://zpt.vatonline.cn/minio/upload/20240322/b5b3540328d847a58cde564627a994c8.png',
      logo: jr,
    },
    {
      bg: 'http://zpt.vatonline.cn/minio/upload/20240322/ca3c327eaac94401bbf811955a0d1000.png',
      logo: zw,
    },
    {
      bg: 'http://zpt.vatonline.cn/minio/upload/20240322/64b37ecfc9fb4b719df82d08ff44c385.png',
      logo: sw
    }
  ]
const Role  = {
    '用户': 1,
    '机器人': 2
  }
  const [active, setActive] = useState(0);
  const showIconRef = useRef(true);
  const inputValueRef = useRef('');
  const Socket = useRef(null);
  const [showIcon, setShowIcon] = useState(true);
  const [showWave, setShowWave] = useState(false);
  const handle = useRef(null)
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(sessionStorage.getItem('openStatus') ? sessionStorage.getItem('openStatus') === 'true' : true);
  const [sex, setSex] = useState(2);
  // const [webSocketStart, setWebSocketStart] = useState(false)
  const [lastIndex, setLastIndex] = useState({
    voiceItems: [
      {
        text: '第一段'
      }
    ]
  });
  const [detailItem, setDetailItem] = useState({
    voiceItems: [{

    }]
  });

  // 用于操作聊天列表元素的引用
  const chatListRef = useRef(null)
  const [avatar] = useState(
    LabAvatar({
      debug: true,
      onEvent(e) {
        if (e.header === AvatarEventHeader.EventMessage) {
          if (e.type === AvatarEventType.VoiceStart) {
            console.info("[ onVoiceStart ] > 播报开始");
          }
          if (e.type === AvatarEventType.VoiceEnd) {
            console.info("[ onVoiceEnd ] > 播报停止");
          }
        }
      },
      onFinishInit() {
        setTimeout(() => {
          setLoading(false)
        }, 600)
        console.info("[ onFinishInit ] >");
      },
      onAvatarMessage: (message) => {
        console.info(message);
      },
      onErrorOccur: (error) => {
        const { message } = JSON.parse(error);
        console.info("交互服务报错", message);
      },
      onAutoplayFailed: () => new Promise((resolve) => {
        Modal.confirm({
          title: '需要浏览器允许播放音频，请确认',
          onOk: () => {
            // setTimeout(()=>{
            //   sendDefaultMsg();
            // }, 500)
            resolve(true);
          }
        })
      })
    })
  );

  const changeValue = (num) => {

    if (active === num) {
      return;
    } else {
      setLoading(true)
      const div = document.getElementById('playdiv-15');
      div && div.parentNode.removeChild(div);
      setActive(num);
    }
  }

  const connect = () => {
    avatar.connect({
      videoElement: videoRef.current,
      // ws://192.168.110.249:8880/virtual_human/api/
      // wss://openspeech.bytedance.com/virtual_human/avatar_biz/echo
      url: "wss://openspeech.bytedance.com/virtual_human/avatar_biz/echo", // 连接url，具体含义请参照参数说明
      config: {
        tta: {
          role: sex === 2 ? "GuoXiaoyuan" : 'XiaoHuan',//请参照配置参数说明 GuoXiaoyuan // XiaoHuan LiuXuan
          extra_param: {
            background: imgList[active].bg,
            role_conf: {
              role_left_offset: sex === 2 ? 120 : 50,
              role_width: 900,
              role_top_offset: 450
            }
          }
        },
        // 新的接入认证：
        // appid kd8joprtcelxjzmx
        // token qsooRFf6w1aRAgTBRoJJ6YdT0UTxCedL
        // old
        // appid  suabwhhiw7z2ga6e
        // token： TQEZfhvWpQcIlOJ9Kzw02X55dkYCmxlW
        request: {
          app_id: appObj.app_id,  //请参照配置参数说明
          req_id: guid(), // 此处使用 node-uuid
          uid: '2100326369',  // 请参照配置参数说明
          token: appObj.token,
        },
      },
    });
  };

  const newWebSocket = () => {
    // window.location.hostname === 'localhost' ? 'wss://portal.infodator.com/wendao_api/local_doc_qa/stream-chat' : 
    const socketUrl = 'wss://portal.infodator.com/wendao_api/local_doc_qa/stream-chat';
    Socket.current = new WebSocket(socketUrl)
    Socket.current.onopen = onopenWS
    Socket.current.onmessage = onmessageWS
    Socket.current.onerror = onerrorWS
    Socket.current.onclose = oncloseWS
  }


  /** 打开WS之后发送心跳 */
  const onopenWS = () => {
    console.log('websocket链接成功')
    sendPing()
  }

  const sendPing = (time = 10000, ping = 'ping') => {
    clearInterval(setIntervalWesocketPush?.current)
    Socket?.current.send(JSON.stringify({ 'ping': 'heart' }))
    setIntervalWesocketPush.current = setInterval(() => {
      Socket?.current.send(JSON.stringify({ 'ping': 'heart' }))
    }, time)
  }

  /** WS数据接收统一处理 */
  const onmessageWS = e => {
    console.log(e, e.data)
      if (e && e.data && e.data!=='health') {
        let msgList = JSON.parse(sessionStorage.getItem('socket'))
        let webSocketStr = sessionStorage.getItem('webSocketStr') || ''
        let webSocketStart = sessionStorage.getItem('webSocketStart')
        if(webSocketStart ===null){
          sessionStorage.setItem('webSocketStart', true)
        }
        if(e.data.startsWith('{"')){
          let obj = JSON.parse(e.data)
          if(obj.flag === 'start'){

          }else if(obj.flag === 'end' ){
            let str = msgList[msgList.length - 1].message;
            const newStr = str.replaceAll(/<[^>]*>/g, '');
            avatar && avatar.sendText(newStr);
            sessionStorage.setItem('webSocketStart', false)
            sessionStorage.setItem('webSocketStr', '')
          }else if(obj.flag == 'append'){
            if(webSocketStart===null ||  webSocketStart){
              webSocketStr = webSocketStr + (obj.append ? obj.append.replace('<br><br>', '<br/>') : obj.append)
            }
            sessionStorage.setItem('webSocketStr', webSocketStr)
            msgList[msgList.length - 1] = {
              loading: false,
              type: 1,
              message: webSocketStr
            }
            setMessageList(msgList)
            sessionStorage.setItem('socket', JSON.stringify(msgList))
          }
        }else{
          
        }
      }
      
  }


  /** 断开重连 */
  const oncloseWS = () => {
    console.log('websocket已断开')
    Socket.current = null
  }

  /** 连接失败重连 */
  const onerrorWS = () => {
    Socket.current && Socket.current.close()
    clearInterval(setIntervalWesocketPush.current)
    console.log('连接失败重连中')
    if (Socket.current.readyState !== 3) {
      Socket.current = null
      newWebSocket()
    }
  }

  const getsocketData = e => {
    // 创建接收消息函数
    const data = e && e.detail.data
    if (data) {
      const json = JSON.parse(data)
      let msgList = JSON.parse(sessionStorage.getItem('socket'))
      if (json.message) {
        const message = JSON.parse(json.message);
        let newContent = '<div style="display: flex;">';
        if (message.length > 0) {
          if (message[0].quick_replies && message[0].quick_replies.length > 0) {
            newContent = message[0].quick_replies.map((val, i) => {
              return `<div data-tagName="payload" class='btn' data-payload=${val.payload} style='' data-name='${val.title}'>${val.title}</div>`
            }).join(' ')
            msgList[msgList.length - 1] = {
              loading: false,
              type: 1,
              message: `
                       <div class='message'>${message[0].text}</div><div style="display: flex;position: absolute;bottom: ${msgList.length > 3 ? 0 : '-50px'}; left: 0;">${newContent}</div>${msgList.length > 3 ? '<div style="height: 50px"></div>' : ''}
                       `
            }
          } else {
            if (message[0].text === 'other') {
              let str = msgList[msgList.length - 2].message;
              sendMsg(str, true)
            } else {
              let isFlag = msgList[msgList.length - 1].type === 1 && msgList[msgList.length - 1].message;
              if(isFlag){
                msgList = [
                  ...msgList,
                  {
                    loading: false,
                    type: 1,
                    message: `<div class='message'>${message[0].text}</div>`
                  }
                ]
              }else{
                msgList[msgList.length - 1] = {
                  loading: false,
                    type: 1,
                    message: `<div class='message'>${message[0].text}</div>`
                }
              }
              let openStatus = sessionStorage.getItem('openStatus');
              if ((openStatus === 'true' || !openStatus)) {
                let newMessage = message[0].text
                const newStr = newMessage.replaceAll(/<[^>]*>/g, '')
                avatar && avatar.sendText(newStr)
              }
              setMessageList(msgList)
              sessionStorage.setItem('socket', JSON.stringify(msgList))
            }
          }
        }
      } else {
        if (json.cmd == 'setid') {
        }
      }

    }
  }
  // 注册监听事件

  // useEffect(() => {
  //   window.addEventListener('onmessageWS', getsocketData)
  //   return () => {
  //     window.removeEventListener('onmessageWS', getsocketData)
  //   }
  // })

  useEffect(() => {
    connect();
    newWebSocket();
    // createSocket();
    return () => {
      avatar.disconnect();
    };
  }, [active, sex]);

  const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const transferTime = (time) => {
    let min = Math.floor(time / 60);
    if (min < 10) {
      min = `0${min}`;
    }
    let sec = Math.floor((time) % 60);
    if (sec < 10) {
      sec = `0${sec}`;
    }
    return `${min}:${sec}`;
  }
  // 开始录音
  const startRecording = (index) => {
    if (detailItem && detailItem?.voiceItems) {
      let list = { ...detailItem };
      list.voiceItems[index].audioFlag = true;
      setDetailItem(list);
      create(index);
    }
    setShowWave(true)
    setLastIndex(index);
  }
  const create = (index) => {
    recorder = new Recorder({
      sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
      sampleRate: 16000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
      numChannels: 1,
    })

    recorder.start().then(() => {
      // 开始录音
      recorder.onprogress = function (params) {
        if (detailItem && detailItem?.voiceItems) {
          let list = { ...detailItem };
          list.voiceItems[index].timeValue = transferTime(params?.duration);
          setDetailItem(list);
        }
      }

    }, (error) => {
      // 出错了
      console.log(`${error.name} : ${error.message}`);
    });
  }
  // 停止录音
  const stopRecording = (index) => {
    recorder && recorder.stop()
    if (detailItem && detailItem?.voiceItems) {
      let list = { ...detailItem };
      list.voiceItems[index].audioFlag = false;
      setDetailItem(list);
    }
    setShowWave(false)
    createDownloadLink(index)
  }

  const dpSpeechASR = async (text) =>{

  }

  // 生成文件
  const createDownloadLink = async (index) => {
    const blob = recorder.getWAVBlob()
    let formDate = new FormData();
    formDate.append("file", blob, "录制音频.wav");
    try {
      const res = await uploadVoice(formDate);
      if (res) {
        if (detailItem && detailItem?.voiceItems) {
          let list = { ...detailItem };
          const item = { ...list.voiceItems[index] }
          list.voiceItems[index] = { ...item, ...res, duration: undefined } || {};
          let response = await quertStr({data: res.data});
          if (response.code === 200) {
            sendMsg(response.content)
          }
          deleteRecord(0);
        }
      }
    } catch (e) {
    }
  }

  // 删除录音
  const deleteRecord = (index) => {
    if (detailItem && detailItem?.voiceItems) {
      let list = { ...detailItem };
      list.voiceItems[index].audioFlag = false;
      list.voiceItems[index].ossPath = '';
      setDetailItem(list);
    }
  }

  useEffect(() => {
    const current = chatListRef.current;
    //scrollHeight是页面的高度
    current.scrollTop = current.scrollHeight - 10
  }, [messageList])

  const submit = async (str = val) => {
    if (!str) {
      return false;
    } else {
      sendMsg(str)
    }
  }

  const sendMsg = (str, isFirst) => {
    let arr = [], history = [];
    let msgList = sessionStorage.getItem('socket') ? JSON.parse(sessionStorage.getItem('socket')) : []
    // msgList.map((val, i) => {
    //   if (i % 2 === 0) {
    //     arr[0] = val.message
    //   } else {
    //     arr[1] = val.message
    //     history.push(arr);
    //     arr = [];
    //   }
    // })
    history = msgList.map(val => {
      return {
        role: val.type == Role.机器人 ? 'assistant' : "user",
        content: val.message
      }
    })
    let newList = [...msgList, {
      type: 2,
      message: str,
      loading: false
    }, {
      type: 1,
      message: '',
      loading: true
    }]
    setMessageList(newList)
    sessionStorage.setItem('socket', JSON.stringify(newList))
    // if (!isFirst) {
    //   let newList = [...msgList, {
    //     type: 2,
    //     message: str,
    //     loading: false
    //   }, {
    //     type: 1,
    //     message: '',
    //     loading: true
    //   }]
    //   setMessageList(newList)
    //   sessionStorage.setItem('socket', JSON.stringify(newList))
    //   let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '12';
    //   const msg = { message: str, userId }
    //   setTimeout(() => {
    //     sendWSPush(msg)
    //   }, 600)
    // } else {
      Socket.current && Socket.current.send(JSON.stringify({
        // query: str,
          query: str,
          knowledge_base_name: '',
          score_threshold: 600,
          history: history.length > 5 ? history.slice(history.length - 5, history.length) : history
          // data,
          // ...newFormObj
        // history: history.length > 5 ? history.slice(history.length - 5, history.length) : history
      }))
    // }
    setValue('');
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown); // 添加全局事件
    window.addEventListener('keyup', onKeyUp); // 添加全局事件
    return () => {
      window.removeEventListener('keydown', onKeyDown); // 销毁
      window.removeEventListener('keyup', onKeyUp); // 添加全局事件
    };
  }, []);

  // 键盘事件
  const onKeyDown = (e) => {
    // handle, setHandle
    if (!showIconRef.current && !handle.current && e.keyCode === 32) {
      handle.current = true;
      startRecording(0)
    }
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 32 && !showIconRef.current) {
      stopRecording(0)
      handle.current = false;
    }
  };

  const _onChange = e => {
    setValue(e.target.value);
    inputValueRef.current = e.target.value;
  };

  const changeIcon = (value) => {
    setShowIcon(value)
    showIconRef.current = value
  }

  const onChangeSex = e => {
    // if(e === 1){
    //   message.error('男性角色已禁用！')
    //   return false
    // }
    if(e === sex) return
    setLoading(true)
    setSex(e)
  };

  const soundWave = (
    <div className="loader">
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
      <div className="span"></div>
    </div>
  )

  const shadow = <div className="shadow">
    松开 发送
  </div>
  return (
    <div className="wrapper web-wrapper">
      {/* <div className="head">
        <h3>容智虚拟人</h3>
      </div> */}
      <div className="content">
        <div className="videos" >
          <Spin size="large" className="loading" spinning={loading}>
            <div className="title-head">
              <div>
                <img alt="" onClick={() => {
                  setOpen(!open)
                  sessionStorage.setItem('openStatus', !open)
                }} src={!open ? closeSound : openSound} style={{ width: open ? '22px' : '26px' }} />
              </div>
              <div>
                <img src={sex === 1 ? boy : boy1} onClick={() => onChangeSex(1)} alt='' />
                <img src={sex === 2 ? girl : girl1} onClick={() => onChangeSex(2)} alt='' />
              </div>
            </div>
            {/* {
              open && (
                <div className="video-ref" ref={videoRef}>
                </div>
              )
            } */}
            <div className="video-ref" ref={videoRef}>
            </div>
          </Spin>
          {
            !loading && (
              <div className="positionBtn">
                <Button onClick={() => changeValue(0)}>客服</Button>
                <Button onClick={() => changeValue(1)}>金融</Button>
                <Button onClick={() => changeValue(2)}>政务</Button>
                <Button onClick={() => changeValue(3)}>税务</Button>
              </div>
            )
          }
        </div>
        <div className="chat-box">
          <div className="title-head" style={{ height: '52px' }}>聊天窗口</div>
          <ul className="list-content" ref={chatListRef}>
            {
              messageList.map((val, index) => (
                <li key={index} className={classNames('list-item', { 'person1': val.type === 1, 'person2': val.type === 2 })}>
                  <div>
                    {
                      val.type === 1 && (
                        <img src={img} alt={''} className="chunk-chat-row-logo-l" />
                      )
                    }
                  </div>
                  {
                    val.loading && !val.message && (
                      <div className={classNames('loading1', { 'left': val.type === 1, 'right': val.type === 2 })}>
                        <div className="rect1"></div>
                        <div className="rect2"></div>
                        <div className="rect3"></div>
                      </div>
                    )
                  }
                  {/* dangerouslySetInnerHTML={{ __html: val.message }} */}
                  {
                    !(val.loading) && (
                      <div className='chunk-chat-item' dangerouslySetInnerHTML={{ __html: val.message }}
                      >
                        {/* {val.message} */}
                      </div>
                    )
                  }
                </li>
              ))
            }
          </ul>
          <div className="list-footer">
            {/* {suffix} */}
            {
              showIcon ?
                (
                  <img
                    src={yy}
                    onClick={() => changeIcon(false)}
                    alt={yy}
                    className='icon' />
                ) :
                (
                  <img
                    src={jp}
                    onClick={() => changeIcon(true)}
                    alt={jp}
                    className='icon' />
                )
            }
            {
              showIcon ?
                (
                  <Input type='text' onPressEnter={() => submit()} onChange={_onChange} value={val} />
                ) :
                (
                  // onMouseDown={() => startRecording(0)}
                  // onMouseUp={() => stopRecording(0)}
                  <div className="empty"
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                  >按住 空格 说话</div>
                )
            }

            <div className="submit-btn" onClick={() => submit()}>
              <img src={send} alt={send} />
            </div>
          </div>
          {
            showWave && soundWave
          }
          {
            showWave && shadow
          }
        </div>
        {
          !loading && (
            <div className="position">
              <img src={imgList[active].logo} />
            </div>
          )
        }
      </div>

    </div>
  );
};

export default App;

