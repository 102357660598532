import request from '../utils/request';

// 获取微信签名
export function createJsapiSignature(url) {
  return request({
    url: 'wechat/createJsapiSignature?url=' + url,
    method: 'get',
  });
}

export function quertStr(data) {
  return request({
    url: 'engine/speech/asr',
    method: 'post',
    data
  });
}

export function uploadVoice(data) {
  return request({
    url: 'SysVideoController/uploadFileLink',
    method: 'post',
    data,
  });
}


export function companyInfo(url) {
  return request({
    url: 'companyInfo/speech?url=' + url,
    method: 'get',
  });
}

