import { Toast } from "antd-mobile";
var isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
 
var recorder; // globally accessible
var microphone;  // 麦克风
// 捕获麦克风
export default function captureMicrophone(callback) {
  console.log("捕获麦克风函数调用")
  if (microphone) {
      callback(microphone);
      return;
  }
//   alert(JSON.stringify(navigator.mediaDevices));
  // 没有媒体设置告知版本低
  if (typeof navigator.mediaDevices === 'undefined' || !navigator.mediaDevices.getUserMedia) {
      var sys = systemall();
      if (sys == "pc") {
          Toast.show("该浏览器不支持语音录入，请使用谷歌、火狐等主流浏览器。");
          return false;
      } else if (sys == "ios") {
          Toast.show("该浏览器不支持语音录入，请将您的IOS操作系统升级最新版本，并使用safari浏览器打开使用。");
          return false;
      } else if (sys == "android") {
          Toast.show("该浏览器不支持语音录入，请使用系统自带浏览器打开使用。");
          return false;
      } else {
          Toast.show("您当前的操作系统不支持语音录入。");
          return false;
      }
  }
 // 获取设备的录音权限
  navigator.mediaDevices.getUserMedia({
      audio: isEdge ? true : { echoCancellation: false }
  }).then(function(mic) {
    console.log("获取麦克风成功回调",mic)
      callback(mic);
  }).catch(function(error) {
    console.log("获取麦克风失败回调",error)
    Toast.show("该浏览器不支持语音录入。或您拒绝了语音授权");
      // 禁用麦克风走这里-》禁用语音按钮
      // 按钮背景换成语音
    //   $("#recordMess").css({
    //     "background":"url(images/noyuyin.png) no-repeat center",
    //     "background-size":" 100% 100%",+- 
    //     })
    //     $("#recordMess").attr('disabled',true);
    //     $('#chatform').children("#messCon").show();
    //     $('#chatform').children("#talkmess_btn").hide();
  })
}

export function systemall(){
    var ios = 'ios';
    var android = 'android';
    var pc = 'pc';
    var u = navigator.userAgent, app = navigator.appVersion;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
       //这个是安卓操作系统
       return android;
    }
    if (isIOS) {
    　　//这个是ios操作系统
        return ios;
    }
    if (isIOS==false&&isAndroid==false) {
    　　//这个是pc操作系统
        return pc;
    }
}


export const monitorSoftKeyboard = callback => {
    // 1. Android系统
    const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
    window.addEventListener('resize', () => {
      //键盘弹起与隐藏都会引起窗口的高度发生变化
      const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
      if (resizeHeight - 0 < originalHeight - 0) {
        //当软键盘弹起，在此处操作
        callback(true);
      } else {
        //当软键盘收起，在此处操作
        callback(false);
      }
    });
    // 2. IOS系统(兼容)
    document.body.addEventListener('focusin', () => {
      //软键盘弹出的事件处理
      callback(true);
    });
    document.body.addEventListener('focusout', () => {
      //软键盘收起的事件处理
      callback(false);
    });
  };