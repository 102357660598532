
import React, { Component } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/index';
import Web from './pages/web/index';
import HuaWei from './pages/huawei/index';
import Mobile from './pages/mobile/index';
import Perform from './pages/perform/index'
import Websocket from './pages/websocket/index'
import device from 'current-device'

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Routes>
              <Route path='/' element={!(device.mobile()) ? <Web /> : <Mobile / > }></Route>
              <Route path='home' element={<Home />}></Route>
              <Route path='huawei' element={<HuaWei />}></Route>
              <Route path='mobile' element={<Mobile />}></Route>
              <Route path='perform' element={<Perform />}></Route>
              <Route path='websocket' element={<Websocket />}></Route>
        </Routes>
      </HashRouter>
    )
  }
}

export default App;