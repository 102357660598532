
import { useState, useRef, useEffect } from "react";
import { LabAvatar, AvatarEventHeader, AvatarEventType } from "byted-ailab-speech-sdk";
import getAMap from '../../utils/getMap'
import { useLocation } from 'react-router-dom'
import { createJsapiSignature, quertStr } from '../../api/index'
import { Modal, Button } from 'antd-mobile'
import classNames from 'classnames'
import './home.css';




function App (props) {
  const videoRef = useRef(null);
  const loading = useRef(false);
  const [messageList, setMessageList] = useState([]);
  // 用于操作聊天列表元素的引用
const chatListRef = useRef(null)
 
  let Timestart = useRef(null)
  const location = useLocation();
  const [avatar] = useState(
    LabAvatar({
      debug: true,
      onEvent(e) {
        if (e.header === AvatarEventHeader.EventMessage) {
          if (e.type === AvatarEventType.VoiceStart) {
            console.info("[ onVoiceStart ] > 播报开始");
          }
          if (e.type === AvatarEventType.VoiceEnd) {
            console.info("[ onVoiceEnd ] > 播报停止");
          }
        }
      },
      onFinishInit() {
        console.info("[ onFinishInit ] >");
      },
      onAvatarMessage: (message) => {
        console.info(message);
      },
      onErrorOccur: (error) => {
        const { message } = JSON.parse(error);
        console.info("交互服务报错", message);
      },
      onAutoplayFailed: async() => 
        new Promise((resolve) => {
          Modal.confirm({
            title: '需要浏览器允许播放音频，请确认',
            onConfirm: () => {
              resolve(true)
            }
          })
        })
    })
  );

  const guid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
  }

  const connect = () => {
    avatar.connect({
      videoElement: videoRef.current,
      url: "wss://openspeech.bytedance.com/virtual_human/avatar_biz/echo", // 连接url，具体含义请参照参数说明
      config: {
        tta: {
          role: "GuoXiaoyuan",//请参照配置参数说明
          clothes_type: 'clothes1',
          pose_tpye: 'pose2',
          voice_type: 'BV405',
          // background: 'https://img-blog.csdnimg.cn/2e485c8006354763858e438c664a19af.png?x-oss-process=image/watermark,type_ZHJvaWRzYW5zZmFsbGJhY2s,shadow_50,text_Q1NETiBATVlHX0c=,size_20,color_FFFFFF,t_70,g_se,x_16'
        },
        request: {
          app_id: "5601986565",  //请参照配置参数说明
          req_id: guid(), // 此处使用 node-uuid 
          uid: '2100326369',  // 请参照配置参数说明
          token: 'zO8wPuHdoYoRwFBFDCCLBTHoGemeO2uG',
        },
      },
    });
  };

  
  useEffect(() => {

  // 销毁组件时断开链接
    return () => {
      avatar.disconnect();
    };
  }, [avatar]);

  useEffect(() => {
    let arr = location.search.split('?name=');
    avatar.sendText(arr[1]);
  }, [location]);

  const queryJdon = async () => {
    const url = 'https://cgxwsoz.infodator.com/';
    let res = await createJsapiSignature(url);
    window.wx && window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
        timestamp: res.timestamp, // 必填，生成签名的时间戳（10位）
        nonceStr: res.nonceStr, // 必填，生成签名的随机串,注意大小写
        signature: res.signature,// 必填，签名，见附录1（通过https://mp.weixin.qq.com/debug/cgi-bin/sandbox?t=jsapisign 验证）
        jsApiList: [
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'startRecord',
          'stopRecord',
          'onVoiceRecordEnd',
          'playVoice',
          'uploadVoice',
          'translateVoice'
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });
      window.wx.ready(function () { //eslint-disable-line
          window.wx.checkJsApi({ //eslint-disable-line
              jsApiList:  [
                'onMenuShareTimeline',
                'onMenuShareAppMessage',
                'startRecord',
                'stopRecord',
                'onVoiceRecordEnd',
                'playVoice',
                'uploadVoice',
                'translateVoice'
              ]
          });
      })
      window.wx.error(function (res) { //eslint-disable-line
          // debugger
          alert("出错了：" + res.errMsg);
      });
  }

  useEffect(() => {
    getAMap();
    queryJdon();
    if(!loading.current){
      connect();
    }
  }, []);

  useEffect(() => {
    const current = chatListRef.current;
    //scrollHeight是页面的高度
    current.scrollTop = current.scrollHeight - 10
  }, [messageList])

  

  const startHandler = (e) =>{
    e.preventDefault();
    // alert('e', JSON.stringify(e));
    let timestart = e.timeStamp
    window.wx.ready(function(){
        window.wx.startRecord({
          success:function(res) {
            console.log("成功回调", res);
          },
          fail:function(error) {
            console.log("失败回调", error);
          }
        })
    });
    // window.wx.startRecord(options);
    Timestart = timestart;
  }
  
  
  const stopHandler = (e) => {
    e.preventDefault();

    let Timeout = e.timeStamp;
    let TimeIng = 0;//录音的时长
    TimeIng = Timeout - Timestart;
    if(TimeIng < 2000){
      alert('录制时间太短，请重新录制！');
      return false;
    }
    window.wx.stopRecord({
        success: function (res) {
            var localId = res.localId;
            window.wx.translateVoice({
              localId: localId, // 需要识别的音频的本地Id，由录音相关接口获得
              isShowProgressTips: 2, // 默认为1，显示进度提示
              success: async function (res) {
                // avatar.sendText(res.translateResult);
                new Promise((resolve) => {
                  setMessageList((num) => {
                    resolve([...messageList, {
                      type: 2,
                      message: res.translateResult
                    }]); // 也可以传空值过去
                    return [...messageList, {
                      type: 2,
                      message: res.translateResult
                    }];
                });
              }).then(async(res) => {
                let newRes = await quertStr(res.translateResult);
                newRes.data && avatar.sendText(newRes.data.answer);
                alert(JSON.stringify(newRes.data));
                setTimeout(()=>{
                  setMessageList([...res, {
                    type: 1,
                    message: newRes.data.answer
                  }])
                }, 1000)
              });
              }
            });
        },
        fail:function(error) {
          console.log("失败回调", error);
          alert("暂停失败：" + JSON.stringify(error));
        }
    }) 
  }

  const addList = async() =>{
    avatar.sendText('上海容智信息技术有限公司是一家专注于AI人工智能技术在企业级应用的高科技公司。' || '123');
  new Promise((resolve) => {
    setMessageList((num) => {
      resolve([...messageList, {
        type: 2,
        message: '312312132'
      }
    ]); // 也可以传空值过去
      return [...messageList, {
        type: 2,
        message: '312312132'
      }
    ];
  });
}).then(res => {
  setTimeout(()=>{
    setMessageList([...res, {
      type: 1,
      message: '312312132'
    }
  ])
  }, 1000)
});
  }


  return (
    <div className="content" style={{}}>
      <div className="video">
        <div ref={videoRef}/>
      </div>
      <div className="message-list" >
        <div className="title"><span>聊天框</span></div>
        <ul ref={chatListRef}>
          {
            messageList.map((val, index)=>(
              <li key={index} className={classNames('message-item', {'person1': val.type === 1, 'person2': val.type === 2})}>
                <span>{val.message}</span>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="footer">
        <Button block color='primary' onClick={addList} size='default' shape='rounded' onTouchStartCapture={startHandler} onTouchEndCapture={stopHandler}>
           长按录音
        </Button>
      </div>
    </div>
  );
};

export default App;