import Cookies from 'js-cookie';

const AuthorizationToken = 'x-auth-token'
let tokenExpiration = 48000 * 60 * 60;

export function setTokenExpiration(expires) {
  tokenExpiration = expires;
}

export function getToken() {
  return Cookies.get(AuthorizationToken);
}

export function setToken(token) {
  Cookies.set(AuthorizationToken, token, {
    expires: new Date(new Date().getTime() + tokenExpiration),
  });
}

export function removeToken() {
  Cookies.remove(AuthorizationToken);
}