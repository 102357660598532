
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import DUIX from 'duix-guiji'
import classNames from 'classnames'
import { Modal, Input, Button, Spin, message, Divider, Checkbox  } from 'antd'

import './index.less'
const { TextArea } = Input;

const iframeUrl  = () => {
  let url = window.location.origin 
  return`${url}/#/perform`
} 


function App() {
  const [connectStatus, setConnectStatus] = useState(false)
  const [connectUrl, setConnectUrl] = useState('')
  const [sendText, setSendText] = useState('')
  const [checked, setChecked] = useState(true)
  const [messageList, setMessageList] = useState([
    {
      type: 1,
      msg: 'fasong neirong ',
      time: '16:05:02'
    },
    {
      type: 2,
      msg: 'fasong neirong ',
      time: '16:05:09'
    },
    {
      type: 1,
      msg: 'fasong neirong ',
      time: '16:05:02'
    },
    {
      type: 2,
      msg: 'fasong neirong ',
      time: '16:05:09'
    },
    {
      type: 1,
      msg: 'fasong neirong ',
      time: '16:05:02'
    },
    {
      type: 2,
      msg: 'fasong neirong ',
      time: '16:05:09'
    },
    {
      type: 1,
      msg: 'fasong neirong ',
      time: '16:05:02'
    },
    {
      type: 2,
      msg: 'fasong neirong ',
      time: '16:05:09'
    },
    {
      type: 1,
      msg: 'fasong neirong ',
      time: '16:05:02'
    },
    {
      type: 2,
      msg: 'fasong neirong ',
      time: '16:05:09'
    },
    {
      type: 2,
      msg: 'fasong neirong ',
      time: '16:05:09'
    },
    {
      type: 1,
      msg: 'fasong neirong ',
      time: '16:05:02'
    },
    {
      type: 2,
      msg: 'fasong neirong ',
      time: '16:05:09'
    },
    {
      type: 1,
      msg: 'fasong neirong ',
      time: '16:05:02'
    },
    {
      type: 2,
      msg: 'fasong neirong ',
      time: '16:05:09'
    }
  ])

  const connectHandler = e =>{
    setConnectUrl(e.target.value)
  }

  const textChangeHandler = e =>{
    setSendText(e.target.value)
  }
  const onChange = (e) => {
    setChecked(e.target.checked)
  }

  const sendMsg = (e) =>{
    checked && setSendText('')
  }
  return (  
    <div  className="wrapper websocket-wrapper">
      <div className='head'>
        <h3>容智虚拟人</h3>
      </div>
      <div className="containers">
        <div className="left p-20px">
          <h3>服务器配置</h3>
          <div className="flex">
            <Input value={connectUrl} onChange={connectHandler} addonBefore="服务地址" /> 
            <Button type="primary" style={{marginLeft:'20px',width: '88px'}}>{ connectStatus ? '断开连接' : '连接' }</Button>
          </div>
          <Divider plain></Divider>
          <TextArea value={sendText}  rows={6} onChange={textChangeHandler} placeholder="需要发送的内容"></TextArea>
          <div style={{padding: '5px 0'}}>
            <Checkbox checked={checked} onChange={onChange}>
              发包清空输入
            </Checkbox>
          </div>
          <Button style={{width: '100%'}} type="primary" size="large" onClick={sendMsg}>发送消息</Button>
        </div>
        <div className="center p-20px">
          <h3>消息记录</h3>
          <Divider plain></Divider>
          <div className="message-list">
            <ul>
              {
                messageList.map(val=>{
                  return (
                    <li style={{marginBottom: '20px'}}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{color: val.type === 1 ? 'blue':'green',marginRight: '8px',fontSize: '16px'}}>{val.type === 1 ? '发送消息':'收到消息'}</span>
                        <b>{val.time}</b>
                      </div>
                      <div style={{fontSize: '16px',marginTop: '5px'}}>{val.msg}</div>
                    </li>
                  )
                })
              }
              
            </ul>
          </div>
        </div>
        <div>
        <h3>虚拟形象</h3>
        <Divider plain></Divider>
          <iframe id="iframe-file" src={iframeUrl()} title='title'
              allow="microphone">
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default App;

