// 字符串转Uint8Array
export const stringToUint8Array = str =>{
    var arr = [];
    // 首先将字符串逐个转换为Unicode值
    for (var i = 0, j = str.length; i < j; ++i) {
      arr.push(str.charCodeAt(i));
    }
    // 再创建 Uint8Array 传递进去 
    var tmpUint8Array = new Uint8Array(arr);
    return tmpUint8Array
  }

export const appObj = () =>{
  return {
    app_id: 'kd8joprtcelxjzmx',
    token: 'qsooRFf6w1aRAgTBRoJJ6YdT0UTxCedL'
    // old
    // app_id: 'suabwhhiw7z2ga6e',
    // token： 'TQEZfhvWpQcIlOJ9Kzw02X55dkYCmxlW'
  }
}