
import { useState, useRef, useEffect, useLayoutEffect } from "react";
import DUIX from 'duix-guiji'
import classNames from 'classnames'
// import './index.css';
// import './index.css'
import './index.less'
import Recorder from 'js-audio-recorder';
import { uploadVoice, quertStr, companyInfo } from '../../api/index'
import { AudioOutlined } from '@ant-design/icons';
import { Input } from 'antd';

const { Search } = Input;
let recorder = null;


function App() {
  let duixRef = useRef(null);
  const [val, setValue] = useState('')
  const [messageList, setMessageList] = useState([]);


  // const [lastIndex, setLastIndex] = useState({
  //   voiceItems: [
  //     {
  //       text: '第一段'
  //     }
  //   ]
  // });
  const [detailItem, setDetailItem] = useState({
    voiceItems: [{

    }]
  });
  // 用于操作聊天列表元素的引用
  const chatListRef = useRef(null)

  const transferTime = (time) => {
    let min = Math.floor(time / 60);
    if (min < 10) {
      min = `0${min}`;
    }
    let sec = Math.floor((time) % 60);
    if (sec < 10) {
      sec = `0${sec}`;
    }
    return `${min}:${sec}`;
  }
  // 开始录音
  const startRecording = (index) => {
    if (detailItem && detailItem?.voiceItems) {
      let list = { ...detailItem };
      list.voiceItems[index].audioFlag = true;
      setDetailItem(list);
      create(index);
    }
    // setLastIndex(index);
  }
  const create = (index) => {
    recorder = new Recorder({
      sampleBits: 16,                 // 采样位数，支持 8 或 16，默认是16
      sampleRate: 16000,              // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
      numChannels: 1,
    })

    recorder.start().then(() => {
      // 开始录音
      recorder.onprogress = function (params) {
        if (detailItem && detailItem?.voiceItems) {
          let list = { ...detailItem };
          list.voiceItems[index].timeValue = transferTime(params?.duration);
          setDetailItem(list);
        }
      }

    }, (error) => {
      // 出错了
      console.log(`${error.name} : ${error.message}`);
    });
  }
  // 停止录音
  const stopRecording = (index) => {
    recorder && recorder.stop()
    if (detailItem && detailItem?.voiceItems) {
      let list = { ...detailItem };
      list.voiceItems[index].audioFlag = false;
      setDetailItem(list);
    }
    createDownloadLink(index)
  }

  // 生成文件
  const createDownloadLink = async (index) => {

    const blob = recorder.getWAVBlob()
    let formDate = new FormData();
    formDate.append("file", blob, "blob.wav");
    formDate.append("serviceId", detailItem?.id || '');
    formDate.append("serviceModel", "1");
    try {
      const res = await uploadVoice(formDate);
      if (res) {
        if (detailItem && detailItem?.voiceItems) {
          let list = { ...detailItem };
          const item = { ...list.voiceItems[index] }
          list.voiceItems[index] = { ...item, ...res, duration: undefined } || {};
          let response = await companyInfo(res.url);
          let newRes = await quertStr(response.data);
          new Promise((resolve) => {
            setMessageList((num) => {
              resolve([...messageList, {
                type: 2,
                message: response.data
              }]); // 也可以传空值过去
              return [...messageList, {
                type: 2,
                message: response.data
              }];
            });
          }).then(async (res) => {
            setTimeout(() => {
              setMessageList([...res, {
                type: 1,
                message: newRes.data.answer
              }])
            }, 1000)
          });
          deleteRecord(0);
        }
      }
    } catch (e) {
    }
  }

  useLayoutEffect(() => {
     duixRef.current = new DUIX({
      containerLable: '.container',
      configUrl: 'https://duix.guiji.ai/render-sdk/config.json',
      sign: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6IjE1MzY2MzkzMTc0NDU4NDA4OTYiLCJleHAiOjE2NzI4MDAwMDQsImlhdCI6MTY3MjcxMzYwNH0.tNVzC4leHyL1ybpoBJkYhkt-CgVTjjJZCbSXuqLPCIc'
    })
    console.log(duixRef.current)
    // duixRef && duixRef.on('intialSucccess', () => {
    //   const sessionId = duixRef.current.start({
    //      robotMode: 'bot',
    //      muted: true,
    //      transparent: false,
    //      wipeGreen: false,
    //  })
    //  console.log('sessionId', sessionId)
    // })
    duixRef.current && duixRef.current?.start({
      openAsr: true,
      transparent: false,
      wipeGreen: false,
      robotMode: "bot",
      vpxMaxBitrate: 0,
    });
    // return () => {
    //   duixRef?.current.stop();
    // };
  }, []);

  // 删除录音
  const deleteRecord = (index) => {
    if (detailItem && detailItem?.voiceItems) {
      let list = { ...detailItem };
      list.voiceItems[index].audioFlag = false;
      list.voiceItems[index].ossPath = '';
      setDetailItem(list);
    }
  }

  useEffect(() => {
    const current = chatListRef.current;
    //scrollHeight是页面的高度
    current.scrollTop = current.scrollHeight - 10
  }, [messageList])

  const submit = async () => {
    if(!val){
      return false;
    }else{
      let newRes = await quertStr(val);
      setValue('');
      new Promise((resolve) => {
        setMessageList((num) => {
          resolve([...messageList, {
            type: 2,
            message: val
          }]); // 也可以传空值过去
          return [...messageList, {
            type: 2,
            message: val
          }];
        });
      }).then(async(res) => {
        setTimeout(()=>{
          setMessageList([...res, {
            type: 1,
            message: newRes.data.answer
          }])
        }, 1000)
      });
    }
  }
    
  const suffix = (
    <AudioOutlined
      onMouseDown={() => startRecording(0)}
      onMouseUp={() => stopRecording(0)}
      style={{
        fontSize: 16,
      }}
      className='audio-svg'
    />
  );

  const _onChange = e => {
    setValue(e.target.value);
  };

  return (
    <div  className="wrapper web-wrapper">
      <div className='head'>
        <h3>容智虚拟人</h3>
      </div>
      <div className="content">
        <div className="videos container" id="container"></div>
        <div className="chat-box">
          <div className="title-head">聊天窗口</div>
          <ul className="list-content" ref={chatListRef}>
            {
              messageList.map((val, index) => (
                <li key={index} className={classNames('list-item', { 'person1': val.type === 1, 'person2': val.type === 2 })}>
                  <span>{val.message}</span>
                </li>
              ))
            }
          </ul>
          <div className="list-footer">
            <Search
              placeholder="请输入需要查询的内容"
              enterButton="发 送"
              size="large"
              suffix={suffix}
              onSearch={submit}
              value={val}
              onChange={_onChange}
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default App;

