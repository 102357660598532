export default function getAMap() {
  return new Promise(function (resolve, reject) {
    // if (isLoaded) {
    //     resolve(window.wx)
    //   return
    // }
    console.log()
    // const ishttps  = document.location.protocol == 'https:';
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://res.wx.qq.com/open/js/jweixin-1.6.0.js`
    script.onerror = reject
    script.onload = function () {
      resolve(window.wx)
    }
    document.head.appendChild(script)
  })
}